.advanced-json-editor {
  display: flex;
  background-color: #fff;
}

.advanced-json-editor .json {
  width: 50%;
  border-right: .25px solid #e1e8f0;
}

.advanced-json-editor .editor {
  width: 50%;
  border-left: .25px solid #e1e8f0;
}

.tab-name {
  padding-right: 4px;
}

.no-selected {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.json-view-container {
  padding: 12px;
  background-color: #fff;
  min-height: 85px;
}

.json-view-container .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.json-view a {
  cursor: pointer;
  text-decoration: none;
}

.action-icons {
  display: inline-flex;
  gap: 4px;
  margin-left: 4px;
  margin-right: 2px;
}

.json-view .toggler {
  width: 1em;
  padding: 0;
  display: block;
  text-align: center;
  float: left;
  background-color: #fff;
}

.json-view .viewer-opened:before {
  content: "-";
}

.json-view .viewer-closed:before {
  content: "+";
}

.json-view .key {
  color: #92278f;
}

.json-view .data-type {
  margin-left: 4px;
  margin-right: 4px;
  font-size: 12px;
  color: #9ca3af;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #9ca3af10;
}

.json-view .value {
  color: #000;
}

.value-label {
  display: inline-flex;
  padding: 1px 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: #3ab54a10;
}

.json-view .value .string {
  color: #3ab54a;
}

.json-view .value .number {
  color: #25aae2;
}

.json-view .value .boolean {
  color: #aa0d91;
}

.json-view .value .null {
  color: #f1592a;
}

.json-view .children {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: .7em;
  padding-left: 1em;
  border-left: .5px solid #d2d2d2;
}

.create-key {
  display: flex;
  justify-content: space-around;
  padding: 0 !important;
}